import * as z from "zod";
import { HearingAids } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { parseWithSchema } from "../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../Infrastructure/queryClient/useQuery";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

export const temporaryHearingSystemStateHearingAidBaseUrl =
    "TemporaryHearingSystemState/HearingAidState?patientId=";
const hearingAidStateSchema = z.object({
    isReadyForProgramming: z.boolean(),
    isPhysicallyConnected: z.boolean(),
});
const temporaryHearingSystemStateBoundedContext = "TemporaryHearingSystemState";
const endpointUrl = (patientId: string | null, hearingAidId: string | null) =>
    `${temporaryHearingSystemStateHearingAidBaseUrl}${patientId}&hearingAidId=${hearingAidId}`;
const notificationNamePrefix = "HearingAidStateChanged_";

export const useHearingAidsConnectionStatusInternal = (
    patientId: string | null,
    hearingAidId: string | null
) => {
    const client = useQueryClient();
    const resultOK = useBaseQuery<HearingAidStateDto>(
        {
            url: endpointUrl(patientId, hearingAidId),
            boundedContext: temporaryHearingSystemStateBoundedContext,
            notifyOnChangeOn: notificationNamePrefix + hearingAidId,
            validate: data => parseWithSchema(hearingAidStateSchema, data),
        },
        {
            enabled: !!hearingAidId,
        }
    );

    useEffect(() => {
        if (resultOK.isError) {
            client.setQueryData<HearingAidStateDto>(
                [
                    temporaryHearingSystemStateBoundedContext,
                    notificationNamePrefix + hearingAidId,
                    endpointUrl(patientId, hearingAidId),
                ],
                { isReadyForProgramming: false, isPhysicallyConnected: false }
            );
        }
    }, [client, hearingAidId, patientId, resultOK]);

    return hearingAidId
        ? resultOK
        : {
              data: {
                  isReadyForProgramming: false,
                  isPhysicallyConnected: false,
              },
          };
};

export const useHearingAidsConnectionStatus = (
    patientId: string | null,
    assignedHIs: HearingAids | null
) => {
    const queryLeftHearingAidConnectionStatus = useHearingAidsConnectionStatusInternal(
        patientId ?? null,
        assignedHIs?.left?.id ?? null
    );
    const queryRightHearingAidConnectionStatus = useHearingAidsConnectionStatusInternal(
        patientId ?? null,
        assignedHIs?.right?.id ?? null
    );

    const isLeftHAReadyForProgramming: boolean = queryLeftHearingAidConnectionStatus.data
        ? queryLeftHearingAidConnectionStatus.data.isReadyForProgramming
        : false;
    const isLeftHAConnected: boolean = queryLeftHearingAidConnectionStatus.data
        ? queryLeftHearingAidConnectionStatus.data.isPhysicallyConnected
        : false;

    const isRightHAReadyForProgramming: boolean = queryRightHearingAidConnectionStatus.data
        ? queryRightHearingAidConnectionStatus.data.isReadyForProgramming
        : false;

    const isRightHAConnected: boolean = queryRightHearingAidConnectionStatus.data
        ? queryRightHearingAidConnectionStatus.data.isPhysicallyConnected
        : false;

    return {
        isLeftHAReadyForProgramming,
        isRightHAReadyForProgramming,
        isLeftHAConnected,
        isRightHAConnected,
        atLeastOneReadyForProgramming: isRightHAReadyForProgramming || isLeftHAReadyForProgramming,
        atLeastOneIsConnected: isRightHAConnected || isLeftHAConnected,
    };
};

export type HearingAidStateDto = z.infer<typeof hearingAidStateSchema>;
