import { Button } from "@wsa/echo-components";
import { t } from "../Infrastructure/Services/LocalizationService";
import { openConnectionOverlay } from "../BoundedContext/PatientProfile/State/actions";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../storeHooks";
import { useAssignedDevicesIdsSelector } from "../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedDevicesIdsSelector";
import { trackAnalyticsEvent } from "@wsa/typescript-tracking-schemas";
import { useIsAnyHearingAidUpdating } from "../BoundedContext/DeviceConfiguration/hooks/useIsAnyHearingAidUpdating";

export const DetectButton = () => {
    const { patientId } = useParams();
    const { leftDeviceId, rightDeviceId } = useAssignedDevicesIdsSelector(patientId);
    const anyHearingAidUpdating = useIsAnyHearingAidUpdating(
        patientId,
        leftDeviceId,
        rightDeviceId
    );
    const dispatch = useAppDispatch();

    const clicked = () => {
        trackAnalyticsEvent.fittingChoices.detect([]);
        dispatch(openConnectionOverlay());
    };

    return (
        <>
            {patientId && (
                <Button
                    id="btn-openDeviceAssignment"
                    size="medium"
                    variant="primary"
                    onClick={clicked}
                    icon={{ type: "noahLink", position: "end" }}
                    disabled={anyHearingAidUpdating}
                    aria-label={"detection button"}>
                    {t("deviceAssignment.detect")}
                </Button>
            )}
        </>
    );
};
