import * as z from "zod";
import { useBaseQuery } from "../../../../Infrastructure/queryClient/useQuery";
import { parseWithSchema } from "../../../../Infrastructure/Services/ValidationUtil";

const useGetFirstFitDateQuery = (patientId: string) =>
    useBaseQuery<FirstFitDate>(
        {
            url: `PatientProfile/FirstFitDate?patientId=${patientId}`,
            boundedContext: "PatientProfile",
            notifyOnChangeOn: "FirstFitDateUpdated_" + patientId,
            validate: result => parseWithSchema(firstFitDateSchema, result),
        },
        {
            enabled: !!patientId,
        }
    );

const firstFitDateSchema = z.object({
    firstFitDate: z.nullable(z.string()),
});

export type FirstFitDate = z.infer<typeof firstFitDateSchema>;

export { useGetFirstFitDateQuery };
