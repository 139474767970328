import { useAssignedHearingAidsSelector } from "./useAssignedHearingAidsSelector";

interface AssignedDevicesIds {
    leftDeviceId: string | undefined;
    rightDeviceId: string | undefined;
}

const useAssignedDevicesIdsSelector = (patientId: string | undefined): AssignedDevicesIds => {
    const { data: devices = { left: null, right: null } } =
        useAssignedHearingAidsSelector(patientId);
    return { leftDeviceId: devices.left?.id, rightDeviceId: devices.right?.id };
};

export { useAssignedDevicesIdsSelector, type AssignedDevicesIds };
