import {
    useGetHearingAidMuteStateQuery,
    useSetLocalHearingAidMuteState,
} from "./useGetHearingAidMuteStateQuery";
import { useSetHearingAidMuteStateMutation } from "./useSetHearingAidMuteStateMutation";

export const useGetHearingAidMuteStateSelector = (patientId: string, hearingAidId: string) => {
    const { data } = useGetHearingAidMuteStateQuery(patientId, hearingAidId);
    const { mutate: setHearingAidMuted } = useSetHearingAidMuteStateMutation();
    const updateLocalMuteState = useSetLocalHearingAidMuteState();

    const toggleLocalMuteState = () => {
        const toggledMuteState = !data?.muted;
        updateLocalMuteState(patientId, hearingAidId, toggledMuteState);
    };

    const toggleHearingAidMuted = () => {
        if (!data) return;
        const toggledMuteState = !data.muted;
        updateLocalMuteState(patientId, hearingAidId, toggledMuteState);
        setHearingAidMuted({
            patientId,
            hearingAidId,
            muted: toggledMuteState,
            onMuteError: toggleLocalMuteState,
        });
    };

    return {
        muted: data?.muted,
        toggleHearingAidMuted,
    };
};
