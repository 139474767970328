import * as z from "zod";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";

const nexusVersionDataSchema = z.object({
    buildVersion: z.string(),
    releaseVersion: z.string(),
});

type NexusVersionData = z.infer<typeof nexusVersionDataSchema>;

export const applicationConfigurationNexusVersionBaseUrl = "NexusVersion/Version";

const useGetBackendVersionQuery = () => {
    return useBaseQuery<NexusVersionData>({
        url: applicationConfigurationNexusVersionBaseUrl,
        boundedContext: "ApplicationConfiguration",
        validate: data => parseWithSchema(nexusVersionDataSchema, data),
    });
};

export { useGetBackendVersionQuery, type NexusVersionData };
