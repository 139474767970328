import { useIsSensogramWorklowActiveSelector } from "../../BoundedContext/sensogram/hooks/useIsSensogramWorklowActiveSelector";
import { useFeedbackTestSelector } from "../../Pages/FeedbackTest/hooks/useFeedbackTestSelector";

export const useGlobalWorkflow = (
    patientId: string | undefined,
    leftHearingAidId: string | undefined,
    rightHearingAidId: string | undefined
) => {
    const { workflowDisabled } = useFeedbackTestSelector(patientId);
    const isSensogramWorkflowActive = useIsSensogramWorklowActiveSelector(
        leftHearingAidId,
        rightHearingAidId
    );
    const isDisabled = workflowDisabled || isSensogramWorkflowActive;

    return {
        forceAllPagesDisabled: isDisabled,
        forceMuteDisabled: isDisabled,
        forceSaveSessionDisabled: isDisabled,
    };
};
