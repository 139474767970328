import React from "react";
import { applicationConfigurationNexusVersionBaseUrl } from "../../BoundedContext/ApplicationConfiguration/hooks/useGetBackendVersionQuery";
import { tempHearingSystemStateFaultyHearingAidStatusBaseUrl } from "../../BoundedContext/TempHearingSystemState/hooks/useGetHearingAidFaultyStateQuery";
import { patientProfileHearingDevicesBaseUrl } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { temporaryHearingSystemStateHearingAidBaseUrl } from "../../State/Hooks/useHearingAidConnectionStatus";
import { analyticsService } from "../Services/AnalyticsService";
import { patientProfileAcousticSelectionBaseUrl } from "../../BoundedContext/PatientProfile/hooks/acousticSelection/useAcousticCouplingsQuery";
import {
    QueryCache,
    QueryClient,
    QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
    queryCache: new QueryCache({
        onSuccess: (data, query) => {
            const queryKeyUrl = query.queryKey[2];
            if (typeof queryKeyUrl !== "string") return;
            if (queryKeyUrl.includes(patientProfileHearingDevicesBaseUrl)) {
                analyticsService.trackSelectionChangeEvent(data, queryKeyUrl);
            }
            if (queryKeyUrl.includes(applicationConfigurationNexusVersionBaseUrl)) {
                analyticsService.trackVersionKnownEvent(data, queryKeyUrl);
            }
            if (queryKeyUrl.includes(temporaryHearingSystemStateHearingAidBaseUrl)) {
                analyticsService.trackConnectionChangeEvent(data, queryKeyUrl);
            }
            if (queryKeyUrl.includes(tempHearingSystemStateFaultyHearingAidStatusBaseUrl)) {
                analyticsService.trackConnectionFaultyStateEvent(data, queryKeyUrl);
            }
            if (queryKeyUrl.includes(patientProfileAcousticSelectionBaseUrl)) {
                analyticsService.trackAcousticCouplingsEvent(data, queryKeyUrl);
            }
        },
    }),
});

interface QueryProps {
    children: React.ReactNode;
}

const QueryClientProvider: React.FC<QueryProps> = ({ children }) => (
    <ReactQueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryClientProvider>
);

export default QueryClientProvider;
