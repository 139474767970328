import { useCallback, useEffect, useRef } from "react";

const useTimeout = (callback: () => void, delay: number, immediate = false) => {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const set = useCallback(() => {
        timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
    }, [delay]);

    const clear = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (immediate) {
            reset();
        }
    }, [immediate, reset]);

    useEffect(() => {
        return clear;
    }, [clear]);

    return { reset, clear };
};

export { useTimeout };
