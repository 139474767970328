import axios, { AxiosRequestConfig } from "axios";
import { ServiceConfigFactory } from "../Services/ServiceConfgFactory";
import { EnvironmentInfoService } from "../Services/EnvironmentInfoService";

export const request = async ({
    relativeUrl,
    method,
    data,
    params,
    accessTokenGenerator,
}: {
    relativeUrl: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    accessTokenGenerator: () => Promise<string>;
}) => {
    const config = await ServiceConfigFactory.createAxiosConfigWithAuth(accessTokenGenerator);
    return axios({
        ...config,
        url: EnvironmentInfoService.getBackendApiUrl() + relativeUrl,
        method,
        data,
        params,
    });
};
