import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import * as z from "zod";

const faultyHearingAidStateSchema = z.object({
    id: z.string(),
    retryable: z.boolean(),
    reasonkey: z.string(),
});

const faultyHearingAidStateOrUndefinedSchema = z.nullable(faultyHearingAidStateSchema);
const sessionFaultsSchema = z.object({
    leftHaFaultyState: faultyHearingAidStateOrUndefinedSchema,
    rightHaFaultyState: faultyHearingAidStateOrUndefinedSchema,
});

export type FaultyHearingAidState = z.infer<typeof faultyHearingAidStateSchema>;

export type SessionFaults = z.infer<typeof sessionFaultsSchema>;

export const tempHearingSystemStateFaultyHearingAidStatusBaseUrl =
    "TemporaryHearingSystemState/FaultyHearingAidStatus";

const useGetHearingAidFaultyStateQuery = (patientId?: string) => {
    const { data } = useBaseQuery<SessionFaults>(
        {
            url: `${tempHearingSystemStateFaultyHearingAidStatusBaseUrl}/${patientId}`,
            boundedContext: "TemporaryHearingSystemState",
            notifyOnChangeOn: "HearingAidOperationStatusChanged",
            validate: dataToValidate => parseWithSchema(sessionFaultsSchema, dataToValidate),
        },
        {
            enabled: !!patientId,
        }
    );
    return { ...data };
};

export { useGetHearingAidFaultyStateQuery };
