import { EarSide } from "../../Common/Types/EarSide";
import { AssignedHearingAid } from "./AssignedHearingAid";
import { NoHearingAid } from "./NoHearingAid";
import { HearingAid as HearingAidType } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";

interface HearingAidProps {
    earSide: EarSide;
    hearingAid: HearingAidType | undefined | null;
    patientId: string;
    isConnected: boolean;
}

export const HearingAid = ({ earSide, hearingAid, patientId, isConnected }: HearingAidProps) => (
    <>
        {hearingAid ? (
            <AssignedHearingAid
                deviceId={hearingAid.id}
                earSide={earSide}
                model={hearingAid.productInfo.productName}
                serialNumber={hearingAid.serialNumber}
                patientId={patientId}
                isConnected={isConnected}
                isDemo={hearingAid.isDemo}
            />
        ) : (
            <NoHearingAid earSide={earSide} />
        )}
    </>
);
