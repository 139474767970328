import { useAuth0 } from "@auth0/auth0-react";
import { request } from "../../../Infrastructure/queryClient/request";
import { useMutation } from "@tanstack/react-query";

interface HearingAidMuteStateParameters {
    patientId: string;
    hearingAidId: string;
    muted: boolean;
    onMuteError: () => void;
}

const useSetHearingAidMuteStateMutation = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ patientId, hearingAidId, muted }: HearingAidMuteStateParameters) => {
            await request({
                relativeUrl: `/TemporaryHearingSystemState/SetHearingAidMuteState`,
                params: { patientId, hearingAidId, muted },
                method: "POST",
                accessTokenGenerator: getAccessTokenSilently,
            });
        },
        onError: (_, { onMuteError }) => {
            onMuteError();
        },
    });
};

export { useSetHearingAidMuteStateMutation, type HearingAidMuteStateParameters };
