import { EarSide } from "../../Common/Types/EarSide";
import { Tooltip, IconButton, Typography } from "@wsa/echo-components";
import { t } from "../../Infrastructure/Services/LocalizationService";
import { HearingAidStatus } from "./HearingAidStatus";
import { HearingAidIndicatorLocal } from "./HearingAidIndicatorLocal";
import { useGetFirstFitCompletedSelector } from "../../BoundedContext/PatientProfile/hooks/firstFit/useGetFirstFitStateSelector";
import { useGlobalWorkflow } from "../hooks/useGlobalWorkflow";
import { useGetHearingAidMuteStateSelector } from "../../BoundedContext/TempHearingSystemState/hooks/useGetHearingAidMuteStateSelector";
import { useGetHearingAidMuteStateQuery } from "../../BoundedContext/TempHearingSystemState/hooks/useGetHearingAidMuteStateQuery";
import { useGetHearingSystemMuteStateQuery } from "../../BoundedContext/TempHearingSystemState/hooks/useGetHearingSystemAllowMuteStateQuery";
import { useAssignedHearingAidsSelector } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { useProgrammingStatusSelector } from "../../BoundedContext/DeviceConfiguration/hooks/useProgrammingStatusSelector";
import { ProductName } from "../ProductName";

interface AssignedHearingAidProps {
    deviceId: string;
    earSide: EarSide;
    model: string;
    serialNumber: string;
    patientId: string;
    isConnected: boolean;
    isDemo: boolean;
}

export const AssignedHearingAid = ({
    deviceId,
    earSide,
    model,
    serialNumber,
    patientId,
    isConnected,
    isDemo,
}: AssignedHearingAidProps) => {
    const { data: assignedHIs } = useAssignedHearingAidsSelector(patientId);
    const { forceMuteDisabled } = useGlobalWorkflow(
        patientId,
        assignedHIs?.left?.id,
        assignedHIs?.right?.id
    );

    const { muted: isMuted, toggleHearingAidMuted } = useGetHearingAidMuteStateSelector(
        patientId,
        deviceId
    );
    const { data: muteState } = useGetHearingAidMuteStateQuery(patientId, deviceId);
    const progammingStatus = useProgrammingStatusSelector(patientId, deviceId);
    const firstFitCompleted = useGetFirstFitCompletedSelector(patientId);
    const { data: allowMuteState } = useGetHearingSystemMuteStateQuery(patientId, deviceId);

    const enableMuteButton =
        isConnected &&
        firstFitCompleted &&
        !forceMuteDisabled &&
        allowMuteState?.allowMute &&
        muteState !== undefined;

    const muteToggleTooltipText = t(
        `widexSubHeader.${isMuted ? "unmute" : "mute"}${
            earSide === "right" ? "Right" : "Left"
        }HearingAid`
    );
    const getIconType = () => {
        if (isMuted) {
            return earSide === "right" ? "soundRightMuted" : "soundLeftMuted";
        } else {
            return earSide === "right" ? "soundRightOn" : "soundLeftOn";
        }
    };

    const muteButton = (
        <div className="echo-dark">
            <IconButton
                aria-label={muteToggleTooltipText}
                size="large"
                className="echo-dark"
                icon={getIconType()}
                variant="ghost"
                disabled={!enableMuteButton}
                data-testid={`mute-unmute-on-the-${earSide}`}
                id={"btn-toggleDevice" + (isMuted ? "Unmuted-" : "Muted-") + earSide}
                onClick={toggleHearingAidMuted}
            />
        </div>
    );

    return (
        <div
            data-testid={`assigned-device-${serialNumber}-on-the-${earSide}`}
            id={serialNumber ? "device-serialNumber-" + serialNumber : undefined}
            className={"subheader-device subheader-device__" + earSide}>
            <HearingAidIndicatorLocal earSide={earSide} isOffline={!isConnected} />
            <div className="device-status echo-dark">
                <Typography
                    variant="body-m"
                    data-testid={`model-name-${earSide}`}
                    className="device-status__model-name">
                    <ProductName productName={model} isDemo={isDemo} showPricePoint={true} />
                </Typography>
                {progammingStatus && (
                    <HearingAidStatus
                        earSide={earSide}
                        programmingStatus={progammingStatus}
                        isConnected={isConnected}
                    />
                )}
            </div>
            {enableMuteButton ? (
                <Tooltip text={muteToggleTooltipText} placement="bottom">
                    {muteButton}
                </Tooltip>
            ) : (
                muteButton
            )}
        </div>
    );
};
