export interface PayloadNotification {
    name: string;
    validator: (payload: unknown) => unknown;
    updater: (oldData: unknown, payload: unknown) => unknown;
}

/**
 * Creates a payload notification object, that should be used as parameter in useHubConnection hook
 *
 * @param name Name of the notification. It is sent as first parameter in the NotifyChange notification. The name should not contain any underscores
 * @param validator Function that validates that the unknown payload type is of the expected type
 * @param updater Function that is used to update the old data from the cache with the partial or full payload from the notification
 */
export const createPayloadNotification = <TPayload, TOldData = TPayload>(
    name: string,
    validator: (payload: unknown) => TPayload,
    updater: (oldData: TOldData | undefined, payload: TPayload) => TOldData
): PayloadNotification => {
    if (name.includes("_")) {
        throw new Error("Notification's name should not contain any underscores");
    }

    return {
        name,
        validator,
        updater: (oldData, payload) => updater(oldData as TOldData, payload as TPayload),
    };
};

/**
 * Extracts only the notification name without the entityId/s. This function is to force the opinionated notification names.
 * e.g "GraphUpdate_100a000b-1c0-400d-80e0-1000000f00g0", "ProgrammingStatusChanged_100a000b-1c0-400d-80e0-1000000f00g0"
 * where the guid after the '_' in most cases is the entityId that changed
 *
 * @param name Full name of the notification including the entityId
 */
export const extractNotificationNameBase = (name: string) => name.split("_")[0];
