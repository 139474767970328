import { useProgrammingStatusSelector } from "./useProgrammingStatusSelector";

export const useIsAnyHearingAidUpdating = (
    sessionId: string | undefined,
    leftHearingAidId: string | undefined,
    rightHearingAidId: string | undefined
) => {
    const leftHearingAidStatus = useProgrammingStatusSelector(sessionId, leftHearingAidId);
    const rightHearingAidStatus = useProgrammingStatusSelector(sessionId, rightHearingAidId);

    return leftHearingAidStatus === "UPDATING" || rightHearingAidStatus === "UPDATING";
};
