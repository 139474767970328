import { useGetFirstFitDateQuery } from "./useGetFirstFitDateQuery";
import {
    useGetFirstFitStateQuery,
    FirstFitStates,
    useSetLocalFirstFitState,
} from "./useGetFirstFitStateQuery";

export type FirstFitState = FirstFitStates | "notReady";

const useGetFirstFitStateSelector = (patientId: string): FirstFitState => {
    const { data } = useGetFirstFitStateQuery(patientId);
    if (data) {
        return data.state;
    }
    return "notReady";
};

const useGetFirstFitCompletedSelector = (patientId: string) => {
    const { data } = useGetFirstFitStateQuery(patientId);
    return data?.state === "fitted";
};

const useSetLocalFirstFitStateSelector = (patientId: string, firstFitState: FirstFitStates) => {
    const setLocalState = useSetLocalFirstFitState();
    return () => {
        setLocalState(patientId, firstFitState);
    };
};

const useGetInitialFittingStateCompletedSelector = (patientId: string | undefined): boolean => {
    const firstFitDate = useGetFirstFitDateQuery(patientId || "").data?.firstFitDate;
    return !!firstFitDate;
};

export {
    useGetFirstFitStateSelector,
    useGetFirstFitCompletedSelector,
    useSetLocalFirstFitStateSelector,
    useGetInitialFittingStateCompletedSelector,
};
