import * as z from "zod";
import { useBaseQuery } from "../../../../Infrastructure/queryClient/useQuery";
import { parseWithSchema } from "../../../../Infrastructure/Services/ValidationUtil";

export const patientProfileAcousticSelectionBaseUrl = "PatientProfile/AcousticSelection?patientId=";

export const useAcousticCouplingsQuery = (patientId: string | undefined) => {
    return useBaseQuery<AcousticCouplingsData[]>(
        {
            url: `${patientProfileAcousticSelectionBaseUrl}${patientId}`,
            boundedContext: "PatientProfile",
            notifyOnChangeOn: patientId,
            validate: data => parseWithSchema(acousticCouplingsDataArraySchema, data),
        },
        {
            enabled: !!patientId,
        }
    );
};

const acousticCouplingsSchema = z.object({
    receiver: z.number().or(z.null()),
    tipType: z.number().or(z.null()),
    ventSize: z.number().or(z.null()),
});

const acousticCouplingsDataSchema = acousticCouplingsSchema.and(
    z.object({
        deviceId: z.string(),
    })
);

const acousticCouplingsDataArraySchema = z.array(acousticCouplingsDataSchema);

export type AcousticCouplings = z.infer<typeof acousticCouplingsSchema>;
export type AcousticCouplingsData = z.infer<typeof acousticCouplingsDataSchema>;
