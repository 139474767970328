import { t } from "../../Infrastructure/Services/LocalizationService";
import "./HIStatusIndicators.scss";
import { EarSide } from "../../Common/Types/EarSide";
import { Typography } from "@wsa/echo-components/dist";
import { DeviceStatus } from "../../BoundedContext/DeviceConfiguration/hooks/useProgrammingStatusSelector";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTimeout } from "../../Common/hooks/useTimeout";

export interface HearingAidStatusProps {
    earSide: EarSide;
    programmingStatus: DeviceStatus;
    isConnected: boolean;
}

export const HearingAidStatus = ({
    earSide,
    programmingStatus,
    isConnected,
}: HearingAidStatusProps) => {
    const recentlyUpdated = useRecentlyUpdated(programmingStatus);
    const statusLabel = useMemo(() => {
        if (!isConnected) return translate("disconnected");
        if (programmingStatus === "UPDATING") return translate("updating");
        if (recentlyUpdated) return translate("updated");
        return translate("connected");
    }, [isConnected, programmingStatus, recentlyUpdated]);

    return (
        <div id={"programming-status-" + earSide}>
            <Typography
                variant="body-xs"
                data-testid={`status-name-${earSide}`}
                className="device-status__indicator">
                {statusLabel}
            </Typography>
        </div>
    );
};

const translate = (text: string) => t(`deviceProgrammingStatus.${text}`);

const useRecentlyUpdated = (programmingStatus: DeviceStatus) => {
    const [recentlyUpdated, setRecentlyUpdated] = useState(false);
    const { reset, clear } = useTimeout(() => setRecentlyUpdated(false), 2000);
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return clear;
        }

        if (programmingStatus === "UPDATED") {
            setRecentlyUpdated(true);
            reset();
        }

        return clear;
    }, [clear, programmingStatus, reset]);

    return recentlyUpdated;
};
