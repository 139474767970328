import * as z from "zod";
import { useQueryClient } from "@tanstack/react-query";
import { useBaseQuery } from "../../../../Infrastructure/queryClient/useQuery";
import { parseWithSchema } from "../../../../Infrastructure/Services/ValidationUtil";

const useGetFirstFitStateQuery = (patientId: string) =>
    useBaseQuery<FirstFitStateWrapper>(
        {
            url: createUrl(patientId),
            boundedContext,
            notifyOnChangeOn: getNotificationKey(patientId),
            validate: result => parseWithSchema(firstFitStateWrapperSchema, result),
        },
        {
            enabled: !!patientId,
        }
    );

const useSetLocalFirstFitState = () => {
    const queryClient = useQueryClient();
    return (patientId: string, firstFitState: FirstFitStates) => {
        queryClient.setQueryData<FirstFitStateWrapper>(getQueryKey(patientId), () => ({
            state: firstFitState,
        }));
    };
};

const getQueryKey = (patientId: string) => [
    boundedContext,
    getNotificationKey(patientId),
    createUrl(patientId),
];
const getNotificationKey = (patientId: string) => "FirstFitStateUpdated_" + patientId;

const firstFitStateSchema = z.enum(["notFitted", "fitting", "fitted"]);

const firstFitStateWrapperSchema = z.object({
    state: firstFitStateSchema,
});

export type FirstFitStates = z.infer<typeof firstFitStateSchema>;
export type FirstFitStateWrapper = z.infer<typeof firstFitStateWrapperSchema>;

const boundedContext = "PatientProfile";
const createUrl = (patientId: string) => `PatientProfile/FirstFitState?patientId=${patientId}`;

export { useGetFirstFitStateQuery, useSetLocalFirstFitState };
