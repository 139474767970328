/* eslint-disable indent */
import "./HIStatusIndicators.scss";
import { useParams } from "react-router-dom";
import { HearingAid } from "./HearingAid";
import { Divider } from "@wsa/echo-components/dist";
import { DetectButton } from "../DetectButton";
import { useAssignedHearingAidsSelector } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { useHearingAidsConnectionStatus } from "../../State/Hooks/useHearingAidConnectionStatus";
import { useSelector } from "react-redux";
import * as simulationModeSelectors from "../../BoundedContext/simulationMode/state/selectors";
interface HIStatusIndicatorsProps {
    withDetectionButton: boolean;
}

const HIStatusIndicators = (props: HIStatusIndicatorsProps) => {
    const { patientId } = useParams();
    const { data: assignedHIs = { left: null, right: null } } =
        useAssignedHearingAidsSelector(patientId);
    const connectionStatuses = useHearingAidsConnectionStatus(patientId ?? null, assignedHIs);

    const isSimulationWorkflow = useSelector(simulationModeSelectors.isSimulationWorkflow);

    const hideButton = connectionStatuses.atLeastOneIsConnected && !isSimulationWorkflow;
    if (!patientId) return null;

    return (
        <>
            <HearingAid
                earSide="right"
                hearingAid={assignedHIs.right}
                patientId={patientId}
                isConnected={connectionStatuses.isRightHAConnected && !isSimulationWorkflow}
            />
            {props.withDetectionButton && !hideButton ? (
                <div className="detection-button-container echo-dark">
                    <DetectButton />
                </div>
            ) : (
                <div className="hi-status-divider-container">
                    <Divider
                        orientation="vertical"
                        className="hi-status-divider-container__divider"
                    />
                </div>
            )}

            <HearingAid
                earSide="left"
                hearingAid={assignedHIs.left}
                patientId={patientId}
                isConnected={connectionStatuses.isLeftHAConnected && !isSimulationWorkflow}
            />
        </>
    );
};

export default HIStatusIndicators;
