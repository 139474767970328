import * as z from "zod";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";

const haAllowMuteStatusSchema = z.object({
    allowMute: z.boolean(),
});

export type HaAllowMuteStatus = z.infer<typeof haAllowMuteStatusSchema>;

const createUrl = (patientId: string, hearingAidId: string) =>
    `TemporaryHearingSystemState/HearingAidAllowMuteState?patientId=${patientId}&hearingAidId=${hearingAidId}`;

const notifyChangeOn = (hearingAidId: string) =>
    `HearingAidAllowChangeMuteStateChanged_${hearingAidId}`;

const useGetHearingSystemMuteStateQuery = (patientId: string, hearingAidId: string) =>
    useBaseQuery<HaAllowMuteStatus>(
        {
            url: createUrl(patientId, hearingAidId),
            boundedContext: "TemporaryHearingSystemState",
            notifyOnChangeOn: notifyChangeOn(hearingAidId),
            validate: result => parseWithSchema(haAllowMuteStatusSchema, result),
        },
        {
            enabled: !!patientId,
        }
    );

export { useGetHearingSystemMuteStateQuery };
