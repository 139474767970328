import * as z from "zod";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { useQueryClient } from "@tanstack/react-query";

const haMuteStatusSchema = z.object({
    muted: z.boolean(),
});

export type HaMuteStatus = z.infer<typeof haMuteStatusSchema>;

const createUrl = (patientId: string, hearingAidId: string) =>
    `TemporaryHearingSystemState/HearingAidMuteState?patientId=${patientId}&hearingAidId=${hearingAidId}`;

const notifyChangeOn = (hearingAidId: string) => `HearingAidMuteStateChanged_${hearingAidId}`;

const boundedContext = "TemporaryHearingSystemState";

const useGetHearingAidMuteStateQuery = (patientId: string, hearingAidId: string) =>
    useBaseQuery<HaMuteStatus>(
        {
            url: createUrl(patientId, hearingAidId),
            boundedContext,
            notifyOnChangeOn: notifyChangeOn(hearingAidId),
            validate: result => parseWithSchema(haMuteStatusSchema, result),
        },
        {
            enabled: !!hearingAidId,
        }
    );

const useSetLocalHearingAidMuteState = () => {
    const queryClient = useQueryClient();
    return (patientId: string, hearingAidId: string, muteStatus: boolean) => {
        queryClient.setQueryData<HaMuteStatus>(
            [boundedContext, notifyChangeOn(hearingAidId), createUrl(patientId, hearingAidId)],
            () => ({ muted: muteStatus })
        );
    };
};

export { useGetHearingAidMuteStateQuery, useSetLocalHearingAidMuteState };
