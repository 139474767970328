import { useFeedbackTestStateQuery } from "./useFeedbackTestStateQuery";

export const useFeedbackTestSelector = (patientId: string | undefined) => {
    const hearingAidsState = useFeedbackTestStateQuery(patientId ?? "");
    const hasState = hearingAidsState.data;

    const workflowDisabled = hasState?.some(x => x.state === "inProgress") ?? false;

    const isAnyHaCalibrated = hasState?.some(x => x.state === "calibrated") ?? false;

    const canStartCalibration = !hasState?.some(
        x => x.state === "inProgress" || x.state === "notReady"
    );
    const isMasterProgramActive =
        hasState === undefined || (hasState?.some(x => x.error === "none") ?? null);
    return {
        workflowDisabled,
        canStartCalibration,
        isMasterProgramActive,
        isAnyHaCalibrated,
    };
};
