import { EarSide } from "../../Common/Types/EarSide";
import { HearingAidsIndicator } from "@wsa/echo-components";
import { t } from "../../Infrastructure/Services/LocalizationService";

interface HearingAidIndicatorLocalProps {
    earSide: EarSide;
    isOffline: boolean;
}

export const HearingAidIndicatorLocal = (props: HearingAidIndicatorLocalProps) => (
    <HearingAidsIndicator
        id={`hearing-aid-indicator-${props.earSide}-${props.isOffline ? "offline" : "online"}`}
        side={props.earSide}
        offline={props.isOffline}
        size="large"
        className="status-bar-hearing-aid-indicator">
        {props.earSide === "right" ? t("common.side.shortRight") : t("common.side.shortLeft")}
    </HearingAidsIndicator>
);
