import * as z from "zod";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { QueryKey, useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";

const measuredAudiogramPointSchema = z.object({
    measured: z.boolean(),
    level: z.number(),
});
const frequencyLevelSchema = z.object({
    frequency: z.number(),
    level: z.number(),
});

const sensogramPointSourceSchema = z.enum(["Audiogram", "Sensogram", "Calculated"]);
const sensogramHTLSchema = z.object({
    value: z.number(),
    source: sensogramPointSourceSchema,
});

const measurementStateSchema = z.enum([
    "NotStarted",
    "Starting",
    "Ongoing",
    "Applying",
    "Canceling",
    "Finished",
    "Deleting",
]);
const playToneStatusSchema = z.enum(["Idle", "InProgress", "Rejected"]);
const prepareToneStatusSchema = z.enum(["Idle", "InProgress", "Rejected"]);

const sensogramSchema = z.object({
    hearingAidId: z.string(),
    minLimit: z.array(z.number()),
    maxLimit: z.array(z.number()),
    audiogramHearingThresholdLevel: z.array(measuredAudiogramPointSchema),
    measuredSensogram: z.array(frequencyLevelSchema),
    requiredFrequencies: z.array(z.number()),
    playToneStatus: playToneStatusSchema,
    prepareToneStatus: prepareToneStatusSchema,
    measurementState: measurementStateSchema,
    requiredFrequenciesOnly: z.boolean(),
    measurementDate: z.nullable(z.string()),
    sensogramHearingThreshold: z.array(sensogramHTLSchema),
});

export type Sensogram = z.infer<typeof sensogramSchema>;
export type PlayToneStatus = z.infer<typeof playToneStatusSchema>;
export type PrepareToneStatus = z.infer<typeof prepareToneStatusSchema>;
export type MeasurementState = z.infer<typeof measurementStateSchema>;
export type FrequencyLevel = z.infer<typeof frequencyLevelSchema>;
export type SensogramPoint = z.infer<typeof sensogramHTLSchema>;
export type AudiogramPoint = z.infer<typeof measuredAudiogramPointSchema>;
export type SensogramPointSource = z.infer<typeof sensogramPointSourceSchema>;

const useGetSensogramQuery = (hearingAidId: string | undefined) => {
    const [boundedContext, notifyOnChangeOn, url] = getQueryKey(hearingAidId);

    return useBaseQuery<Sensogram>(
        {
            url,
            boundedContext,
            notifyOnChangeOn,
            validate: result => parseWithSchema(sensogramSchema, result),
        },
        {
            enabled: !!hearingAidId,
        }
    );
};

const getQueryKey = (hearingAidId: string | undefined): QueryKey => {
    return ["Sensogram", hearingAidId, `Sensogram/GetSensogram?hearingAidId=${hearingAidId}`];
};

useGetSensogramQuery.getQueryKey = getQueryKey;

export { useGetSensogramQuery };
