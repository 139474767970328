import { useGetProgrammingStatusQuery } from "./useGetProgrammingStatusQuery";

export type DeviceStatus = "UPDATED" | "UPDATING";

export const useProgrammingStatusSelector = (
    sessionId: string | undefined,
    hearingAidId: string | undefined
) => {
    const { data } = useGetProgrammingStatusQuery<DeviceStatus>(sessionId, hearingAidId, {
        select: data => (data.isProgramming ? "UPDATING" : "UPDATED"),
    });

    return data;
};
