import { NexusVersionData } from "../../BoundedContext/ApplicationConfiguration/hooks/useGetBackendVersionQuery";
import { eventContext, setEventContext } from "./EventContext/EventContext";
import { logVerbose } from "./LoggingService";
import Telemetry from "./Telemetry";
import { telemetryCustomDataProvider } from "./TelemetryCustomDataProvider";
import {
    trackEvent,
    stopTrackPage as stopTrackPageAI,
    startTrackPage as startTrackPageAI,
    trackException,
    encryptedLocalStorage,
    eventContextKey,
    noahSessionIdKey,
    scenarioHashKey,
    setHcpId,
    setSessionId,
    traceStateKey,
    userIdKey,
    setTraceStateString,
    setSessionIdFromNoahTrackingId,
    setDefaultSessionId,
} from "./ApplicationInsights";
import isEqual from "lodash/isEqual";
import qs from "qs";
import { trackAnalyticsEvent } from "@wsa/typescript-tracking-schemas";
import { HearingAidDto } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { AcousticCouplingsData } from "../../BoundedContext/PatientProfile/hooks/acousticSelection/useAcousticCouplingsQuery";

/**
 * Analytics wraps Application Insigths and adds schema-version, event-context and feature-data to customDimensions
 * before calling Application Insights trackEvent(..).
 */
class AnalyticsService {
    /**
     * The event-schema to match all events against (when calling trackEvent(..))
     */
    //private readonly schema = new EventSchema();

    initalize(telemetryClient: Telemetry) {
        this.telemetryClient = telemetryClient;

        if (window.location.href.includes("noahTrackingId")) {
            localStorage.removeItem(noahSessionIdKey);
            localStorage.removeItem(traceStateKey);
            localStorage.removeItem(scenarioHashKey);
            localStorage.removeItem(eventContextKey);
            localStorage.removeItem(userIdKey);

            const values = qs.parse(window.location.href, { ignoreQueryPrefix: true });
            const noahTrackingId = values.noahTrackingId as string;
            //We will get noahTrackingId while we transfer the patient.
            //We will not get it upon page reload or opening new tab - then we read this value from localStorage in the else clause
            setSessionIdFromNoahTrackingId(noahTrackingId);
            trackAnalyticsEvent.session.initiated();
        } else {
            const savedNoahSessionId = localStorage.getItem(noahSessionIdKey);
            if (savedNoahSessionId) {
                setSessionId(savedNoahSessionId);
            } else {
                setDefaultSessionId();
            }

            const userId = localStorage.getItem(userIdKey);
            if (userId) {
                setHcpId(userId);
            }

            const savedTraceStateString = encryptedLocalStorage.getItem(traceStateKey);
            if (savedTraceStateString) {
                setTraceStateString(savedTraceStateString);
            }

            const deserializedEventContext = encryptedLocalStorage.getItem(eventContextKey);
            if (deserializedEventContext) {
                setEventContext(deserializedEventContext);
            }
        }
    }

    private telemetryClient: Telemetry = new Telemetry(null);
    /**
     * Gets the event version from the schema (or no version if no schema match) and sets the event context.
     * Then calls the wrapped Application Insights trackEvent(..) with the name, version, context and feature data.
     * The following line is suppose to be added when we have schema versioning // let version = this.schema.getVersion(name, feature);
     * @param name - the name of the event to track
     * @param feature - the data of the event to track
     * @param parent - the name of the parent event of the event given by the 'name' parameter
     * @param logType - the type of the logged item
     */
    trackAnalyticsEvent(
        name: string,
        feature: any,
        parent: string | undefined,
        eventSchemaVersion: any = undefined,
        logType: any = undefined
    ): void {
        eventContext().replace(name, feature, parent);
        const context = eventContext().getContextForParticularEvent(name);
        const eventParent = eventContext().getParent(name) as any;
        const customDimensionsProperties = {
            eventParent,
            context,
            feature,
            logType,
            eventSchemaVersion,
        };
        logVerbose(`trackAnalyticsEvent: ${JSON.stringify({ name, customDimensionsProperties })}`);
        trackEvent(analyticsService.telemetryClient, name, customDimensionsProperties);
    }

    trackAnalyticsException(
        exception: Error,
        feature: any,
        parent: string | undefined,
        logType: any = undefined
    ): void {
        eventContext().replace(exception.name, feature, parent);
        const context = eventContext().getContextForParticularEvent(exception.name);
        const eventParent = eventContext().getParent(exception.name) as any;
        const hearingAidsAndVersionProperties =
            telemetryCustomDataProvider.getHearingAidsAndVersionProperties();
        const combinedFeatureProperties = { ...feature, ...hearingAidsAndVersionProperties };
        const customDimensionsProperties = {
            eventParent,
            context,
            feature: combinedFeatureProperties,
            logType,
        };
        logVerbose(
            `trackAnalyticsException: ${JSON.stringify({
                name: exception.name,
                customDimensionsProperties,
            })}`
        );
        trackException(this.telemetryClient, exception, customDimensionsProperties);
    }

    extractHearingAidId(url: string): string | null {
        const regex = /hearingAidId=([a-zA-Z0-9-]+)/;
        const match = regex.exec(url);
        if (match && match[1]) {
            return match[1];
        }

        return null;
    }

    trackConnectionChangeEvent(data: any, queryKeyUrl: string) {
        const hearingAidId = this.extractHearingAidId(queryKeyUrl);
        telemetryCustomDataProvider.setConnectionStatus(
            hearingAidId!,
            data.isReadyForProgramming,
            data.isPhysicallyConnected
        );

        trackAnalyticsEvent.connection.change({
            ...telemetryCustomDataProvider.getConnectionChangeProperties(),
        });
    }

    trackVersionKnownEvent(data: any, queryKeyUrl: string) {
        const versionData = data as NexusVersionData;
        telemetryCustomDataProvider.setBackendVersion(versionData?.buildVersion);
        trackAnalyticsEvent.version.known({
            ...telemetryCustomDataProvider.getVersionProperties(),
        });
    }

    trackAcousticCouplingsEvent(data: any, queryKeyUrl: string) {
        const acousticCouplingsData = data as AcousticCouplingsData[];
        const assignedHearingAidsProperties =
            telemetryCustomDataProvider.getHearingAidsProperties();
        const leftAcousticCoupling = acousticCouplingsData.find(
            singleAcousticCoupling =>
                singleAcousticCoupling.deviceId === assignedHearingAidsProperties.left?.id
        );
        const rightAcousticCoupling = acousticCouplingsData.find(
            singleAcousticCoupling =>
                singleAcousticCoupling.deviceId === assignedHearingAidsProperties.right?.id
        );
        const getAcousticCouplingForSide = (
            acousticCoupling: AcousticCouplingsData | undefined
        ) => {
            return acousticCoupling !== undefined
                ? {
                      id: acousticCoupling.deviceId,
                      receiver: acousticCoupling.receiver ?? undefined,
                      tipType: acousticCoupling.tipType ?? undefined,
                      ventSize: acousticCoupling.ventSize ?? undefined,
                  }
                : undefined;
        };
        trackAnalyticsEvent.acousticCouplings.change({
            left: getAcousticCouplingForSide(leftAcousticCoupling),
            right: getAcousticCouplingForSide(rightAcousticCoupling),
        });
    }

    trackSelectionChangeEvent(data: any, queryKeyUrl: string) {
        const hearingAidsData = data as HearingAidDto[];
        const previousHearingAidsProperties =
            telemetryCustomDataProvider.getHearingAidsProperties();
        telemetryCustomDataProvider.setAssignedHearingAids(hearingAidsData);
        const currentHearingAidsProperties = telemetryCustomDataProvider.getHearingAidsProperties();

        if (!isEqual(previousHearingAidsProperties, currentHearingAidsProperties))
            trackAnalyticsEvent.selection.change(currentHearingAidsProperties);
    }

    trackConnectionFaultyStateEvent(data: any, queryKeyUrl: string) {
        const leftHearingAidId = data?.leftHaFaultyState?.id;
        if (leftHearingAidId)
            telemetryCustomDataProvider.setConnectionStatus(leftHearingAidId, false, false);
        const rightHearingAidId = data?.rightHaFaultyState?.id;
        if (rightHearingAidId)
            telemetryCustomDataProvider.setConnectionStatus(rightHearingAidId, false, false);

        if (leftHearingAidId || rightHearingAidId) {
            trackAnalyticsEvent.connection.change({
                ...telemetryCustomDataProvider.getConnectionChangeProperties(),
            });
            trackAnalyticsEvent.connection.faultedState({
                HAFaultyState: {
                    left: data?.leftHaFaultyState ?? undefined,
                    right: data?.rightHaFaultyState ?? undefined,
                },
            });
        }
    }

    startTrackPage(eventName: string) {
        startTrackPageAI(this.telemetryClient, eventName);
    }

    stopTrackPage(eventName: string, dictionary: { [name: string]: string } = {}) {
        stopTrackPageAI(this.telemetryClient, eventName, window.location.href, dictionary);
    }
}

export const analyticsService = new AnalyticsService();
