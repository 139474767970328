export const organizationIdQueryParamKey = "auth0OrganizationId";

export const getOrganizationId = () => {
    const organizationId = getQueryParams()[organizationIdQueryParamKey] ?? null;

    if (organizationId) {
        console.log("OrganizationId: ", organizationId);
    }

    return organizationId;
};

const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });
    return queryParams;
};
