import { t } from "../Infrastructure/Services/LocalizationService";
import { Trans } from "react-i18next";

interface ProductNameProps {
    productName: string;
    isDemo: boolean;
    showPricePoint?: boolean;
}

export const ProductName = ({ productName, isDemo, showPricePoint }: ProductNameProps) => {
    const displayProductName = showPricePoint === true ? productName : productName?.split(" ")[0];
    return (
        <>
            {productName === "unknown" ? (
                t("deviceAssignment.unsupportedHearingAid")
            ) : isDemo ? (
                <Trans
                    i18nKey="common.demoModel"
                    values={{
                        model: displayProductName,
                    }}
                />
            ) : (
                productName
            )}
        </>
    );
};
