import HIStatusIndicators from "./HIStatusIndicators";
import { Routes, Route } from "react-router-dom";
import { patientIdTemplate, patientsPath } from "../../Infrastructure/Services/NavigationService";

export const HearingAids = () => (
    <div className="hearing-aids-container" data-tour-target="hearing-aids-container">
        <Routes>
            <Route
                path={patientsPath + "/" + patientIdTemplate + "/*"}
                element={<HIStatusIndicators withDetectionButton={false} />}
            />
            <Route
                path={patientsPath + "/" + patientIdTemplate}
                element={<HIStatusIndicators withDetectionButton={true} />}
            />
        </Routes>
    </div>
);
