import { useGetSensogramQuery } from "./useGetSensogramQuery";

const useIsSensogramWorklowActiveSelector = (
    leftHearingAidId: string | undefined,
    rightHearingAidId: string | undefined
): boolean => {
    const { data: leftData } = useGetSensogramQuery(leftHearingAidId);
    const { data: rightData } = useGetSensogramQuery(rightHearingAidId);

    const isStarted =
        leftData?.measurementState === "Ongoing" || rightData?.measurementState === "Ongoing";
    const isStarting =
        leftData?.measurementState === "Starting" || rightData?.measurementState === "Starting";
    const isCanceling =
        leftData?.measurementState === "Canceling" || rightData?.measurementState === "Canceling";
    const isApplying =
        leftData?.measurementState === "Applying" || rightData?.measurementState === "Applying";
    const isDeleting =
        leftData?.measurementState === "Deleting" || rightData?.measurementState === "Deleting";

    if (isStarting || isStarted || isCanceling || isApplying || isDeleting) {
        return true;
    }

    return false;
};

export { useIsSensogramWorklowActiveSelector };
