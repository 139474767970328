import { EarSide } from "../../Common/Types/EarSide";
import { IconButton, Typography } from "@wsa/echo-components";
import { HearingAidIndicatorLocal } from "./HearingAidIndicatorLocal";
import { t } from "../../Infrastructure/Services/LocalizationService";

export const NoHearingAid = ({ earSide }: { earSide: EarSide }) => {
    const getIconType = () => {
        return earSide === "right" ? "soundRightMuted" : "soundLeftMuted";
    };
    return (
        <div className={"subheader-device subheader-device__" + earSide}>
            <HearingAidIndicatorLocal earSide={earSide} isOffline={true} />
            <Typography variant="body-m"
                data-testid={`no-device-on-the-${earSide}`}
                className="device-status__unavailable">
                {t("widexSubHeader.noHearingInstruemtnAssignedOnSide")}
            </Typography>
            <div className="echo-dark">
                <IconButton
                    aria-label={t("widexSubHeader.adjustHearingAidOnSide", {
                        earSide,
                        direction: "mute",
                    })}
                    className="echo-dark"
                    size="large"
                    icon={getIconType()}
                    variant="ghost"
                    disabled={true}
                />
            </div>
        </div>
    );
};
