import * as z from "zod";
import { QueryKey, useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { createPayloadNotification } from "../../../Infrastructure/Services/hubConnection/createPayloadNotification";

const programmingStatusSchema = z.object({
    isProgramming: z.boolean(),
});
const programmingStatusPayloadSchema = z.boolean();

type ProgrammingStatus = z.infer<typeof programmingStatusSchema>;

const useGetProgrammingStatusQuery = <TSelector = ProgrammingStatus>(
    sessionId: string | undefined,
    hearingAidId: string | undefined,
    options?: {
        select?: (data: ProgrammingStatus) => TSelector;
    }
) => {
    const [boundedContext, notifyOnChangeOn, url] = getQueryKey(sessionId, hearingAidId);

    return useBaseQuery<ProgrammingStatus, TSelector>(
        {
            url,
            boundedContext,
            notifyOnChangeOn,
            validate: result => parseWithSchema(programmingStatusSchema, result),
        },
        {
            enabled: !!hearingAidId && !!sessionId,
            select: options?.select,
        }
    );
};

const getQueryKey = (sessionId: string | undefined, hearingAidId: string | undefined): QueryKey => {
    return [
        "DeviceConfiguration",
        `${programmingStatusNotification.name}_${hearingAidId}`,
        `DeviceConfiguration/GetProgrammingStatus?sessionId=${sessionId}&hearingAidId=${hearingAidId}`,
    ];
};

const programmingStatusNotification = createPayloadNotification<boolean, ProgrammingStatus>(
    "ProgrammingStatusChanged",
    payload => parseWithSchema(programmingStatusPayloadSchema, payload),
    (_oldData, payload) => ({ isProgramming: payload })
);

useGetProgrammingStatusQuery.getQueryKey = getQueryKey;
export { useGetProgrammingStatusQuery, type ProgrammingStatus, programmingStatusNotification };
