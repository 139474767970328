import * as z from "zod";
import { fromError } from "zod-validation-error";

export const parseWithSchema = (zodSchema: z.ZodType, inputs: unknown) => {
    try {
        if (zodSchema instanceof z.ZodString) {
            return zodSchema.parse(inputs);
        } else {
            return z.preprocess(emptyToUndefined, zodSchema).parse(inputs);
        }
    } catch (err) {
        const formattedError = fromError(err);
        console.error(formattedError);
        throw formattedError;
    }
};

const emptyToUndefined = (data: any) => (data === "" ? undefined : data);
